import Step from "../../../@extensions/Step";
import required from "@libs/required";

class NameStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get textFieldStore() {
		return super.textFieldStore;
	}

	get progress() {
		return super.progress;
	}

	get name() {
		return super.name;
	}

	get isClientAuthenticated() {
		return super.isClientAuthenticated;
	}

	async init() {
		if (this.isClientAuthenticated) return super.next();
	}

	async next(ref = required`ref`) {
		try {
			if (this.textFieldStore.isValid(ref.current.id) === false) return;
			await this.signupAsClient();
			super.next();
		} catch (err) {
			throw err;
		}
	}

	prev() {
		super.prev();
	}

	update(val) {
		super.updateName(val);
	}

	signupAsClient() {
		return super.signupAsClient();
	}
}

export default NameStore;
