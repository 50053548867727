class StepExtension {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get textFieldStore() {
		const { textFieldStore } = this.rootStore.stores;
		return textFieldStore;
	}

	get accountStore() {
		const { accountStore } = this.rootStore.stores;
		return accountStore;
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	get leadFunnelModalStore() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		return leadFunnelModalStore;
	}

	get nextElement() {
		return document.getElementsByClassName("question-next")[0];
	}

	get skipElement() {
		return document.getElementsByClassName("question-skip")[0];
	}

	get mode() {
		if (this.skipElement) return "skip";
		if (this.nextElement) return "next";
	}

	get password() {
		return this.leadFunnelStore.Client.password;
	}

	get email() {
		return this.leadFunnelStore.Client.email;
	}

	get phone() {
		return this.leadFunnelStore.Client.phone;
	}

	get textNotification() {
		return this.leadFunnelStore.Client.textNotification;
	}

	get first_name() {
		return this.leadFunnelStore.Client.first_name;
	}

	get last_name() {
		return this.leadFunnelStore.Client.last_name;
	}

	get name() {
		return this.leadFunnelStore.Client.name;
	}

	get progress() {
		return this.leadFunnelStore.Control.progress;
	}

	get zipcode() {
		return this.leadFunnelStore.Lead.zipcode;
	}

	get service() {
		return this.leadFunnelStore.Lead.service;
	}
	get provider() {
		return this.leadFunnelStore.Provider.value;
	}

	get lead() {
		return this.leadFunnelStore.Lead.value;
	}

	get accountId() {
		return this.accountStore._id;
	}

	get focus() {
		try {
			this.validate();
			return true;
		} catch (err) {
			return false;
		}
	}

	get isAuthenticated() {
		return this.accountStore.isAuthenticated;
	}

	get isClientAuthenticated() {
		return this.leadFunnelStore.Client.isAuthenticated;
	}

	async init() {
		return true;
	}

	validate() {
		return true;
	}

	next({ validate = true } = {}) {
		try {
			if (validate) this.validate();
		} catch (err) {
			throw err;
		}
		this.leadFunnelStore.Control.next();
	}

	prev(...args) {
		this.leadFunnelStore.Control.prev(...args);
	}

	continue() {
		const { currentStep, prevStep } = this.leadFunnelStore.Control;
		if (currentStep > prevStep) {
			this.leadFunnelStore.Control.next();
			return;
		}
		this.leadFunnelStore.Control.prev();
	}

	login() {
		return this.leadFunnelStore.Client.login();
	}

	updatePassword(val) {
		this.leadFunnelStore.Client.updateObservableField("password", val);
	}

	updateEmail(val) {
		this.leadFunnelStore.Client.updateObservableField("email", val);
	}

	updatePhone(val) {
		this.leadFunnelStore.Client.updateObservableField("phone", val);
	}

	updateTextNotification(val) {
		this.leadFunnelStore.Client.updateObservableField("textNotification", val);
	}

	updateName(val) {
		this.leadFunnelStore.Client.updateObservableField("name", val);
	}

	updateZipcode(val) {
		this.leadFunnelStore.Lead.updateZipcode(val);
	}

	createLead() {
		return this.leadFunnelStore.Lead.create();
	}

	signupAsClient() {
		return this.leadFunnelStore.Client.signupAsClient();
	}

	getClient() {
		return this.leadFunnelStore.Client.get();
	}

	updateClient() {
		return this.leadFunnelStore.Client.update();
	}

	updateClientNotification() {
		return this.leadFunnelStore.Client.updateNotification();
	}
}

export default StepExtension;
