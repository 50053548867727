import { observable, action } from "mobx";
import required from "@libs/required";

import AnswerInstance from "../../../@extensions/AnswerInstance";

class Date extends AnswerInstance {
	@observable time = "6:30 AM";

	@observable hours = 2;

	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get times() {
		return [
			"6:00 AM",
			"6:30 AM",
			"7:00 AM",
			"7:30 AM",
			"8:00 AM",
			"8:30 AM",
			"9:00 AM",
			"9:30 AM",
			"10:00 AM",
			"10:30 AM",
			"11:00 AM",
			"11:30 AM",
			"12:00 PM",
			"12:30 PM",
			"1:00 PM",
			"1:30 PM",
			"2:00 PM",
			"2:30 PM",
			"3:00 PM",
			"3:30 PM",
			"4:00 PM",
			"4:30 PM",
			"5:00 PM",
			"5:30 PM",
			"6:00 PM",
			"6:30 PM",
			"7:00 PM",
			"7:30 PM",
			"8:00 PM",
			"8:30 PM",
			"9:00 PM",
			"9:30 PM",
			"10:00 PM",
			"10:30 PM",
			"11:00 PM",
			"11:30 PM",
		];
	}

	init() {
		super.init();
		if (!this.currentLeadAnswer) {
			this.removeSelectedAnswer(this.answer._id);
			this.addSelectedAnswer(this.answer._id, this.value, null, {
				time: this.time,
				hours: this.hours,
			});
			return;
		}

		const { __state } = this.currentLeadAnswer || {};
		if (__state) {
			this.time = __state.time;
			this.hours = __state.hours;
		}
	}

	get unit() {
		if (this.hours > 1) return "hours";
		return "hour";
	}

	get value() {
		if (!this.time || !this.hours) return;
		return `${this.time} for ${this.hours} ${this.unit}`;
	}

	get addSelectedAnswer() {
		return super.addSelectedAnswer;
	}

	get removeSelectedAnswer() {
		return super.removeSelectedAnswer;
	}

	@action
	update(type = required`type`, val = required`val`, answerId) {
		switch (type) {
			case "time":
				this.time = val;
				break;
			case "hours":
				this.hours = val;
				break;
		}
		if (!this.value) return;
		this.removeSelectedAnswer(answerId || this.answer._id);
		this.addSelectedAnswer(answerId || this.answer._id, this.value, null, {
			time: this.time,
			hours: this.hours,
		});
	}
}

export default Date;
