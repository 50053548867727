import Step from "../../../@extensions/Step";

class IntroStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	next() {
		super.next();
	}

	get provider() {
		return super.leadFunnelStore.Provider.value;
	}

	get type() {
		return super.leadFunnelModalStore.leadActionType;
	}

	get image() {
		return this.provider?.image?.value;
	}

	get companyName() {
		return this.provider?.company_name;
	}
}

export default IntroStore;
