import Step from "../../../@extensions/Step";
import { observable, action } from "mobx";
import { createLeadValidation } from "../../../@data/validation";

import required from "@libs/required";

import LeadQuestionAnswers from "@templates/LeadQuestionAnswers";
const { SITE_NAME } = process.env;
class LeadFunnelQuestionStore extends Step {
	@observable currentStep = 0;
	@observable showSkip = true;

	completedQuestions = new Set();

	@action
	reset() {
		this.currentStep = 0;
		this.showSkip = true;
		this.completedQuestions = new Set();
	}

	constructor(rootStore) {
		super(rootStore);
	}

	get leadFunnelStore() {
		return super.leadFunnelStore;
	}

	get mode() {
		return super.mode;
	}

	get questions() {
		return this.leadFunnelStore.Questions.value;
	}

	get steps() {
		return this.questions.length;
	}

	get question() {
		return this.questions[this.currentStep];
	}

	preSelectedAnswers;

	hasAnswer() {
		const { value } = this.leadFunnelStore.Lead.findAnswerByQuestionId(
			this.question._id
		);
		return !!value;
	}

	findAnswerComponentByType(type = required`type`) {
		return LeadQuestionAnswers.find((template) => template.name === type).value;
	}

	validate() {
		const { value } = this.leadFunnelStore.Lead.findAnswerByQuestionId(
			this.question._id
		);
		try {
			createLeadValidation.validateSyncAt("input.answers[0]", {
				input: { answers: value },
			});
		} catch (err) {
			throw err;
		}
	}

	next() {
		if (this.mode === "skip") {
			this.skip();
			return;
		}

		try {
			this.validate();
		} catch (err) {
			throw err;
		}

		this.completedQuestions.add(this.question._id);
		if (this.currentStep + 1 === this.steps) {
			super.next();
			return;
		}

		this.currentStep = this.currentStep + 1;
	}

	prev() {
		if (this.currentStep === 0) {
			this.leadFunnelStore.Control.prev();
			return;
		}
		this.currentStep = this.currentStep - 1;
	}

	skip() {
		if (this.currentStep + 1 === this.steps) {
			super.next({ validate: false });
			return;
		}
		this.currentStep = this.currentStep + 1;
	}
}

export default LeadFunnelQuestionStore;
