import required from "@libs/required";

import Extension from "../../../@extensions/Answer";
import InputCheckboxText from "./InputCheckboxText";

class InputCheckboxTextStore extends Extension {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`) {
		super.init(question, answer, InputCheckboxText);
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return super.findInstanceByAnswerId(answerId);
	}

	reset() {
		super.reset();
	}
}

export default InputCheckboxTextStore;
