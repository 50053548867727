import Step from "../../../@extensions/Step";

class ChooseService extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get leadFunnelStore() {
		return super.leadFunnelStore;
	}

	get service() {
		return super.service;
	}

	get provider() {
		return super.provider;
	}

	get affinities() {
		if (!this.service?.affinities) return this.provider?.services || [];
		const proServices = this.provider?.services || [];
		const selectedProServiceIds = proServices.map((service) => service._id);
		const services = this.service?.affinities.filter(
			({ affinity }) => !affinity.midtail && affinity.active
		);
		const affinities = services
			.filter(
				({ affinity }) =>
					!this.provider || selectedProServiceIds.includes(affinity._id)
			)
			.map(({ affinity }) => affinity);

		if (affinities.length === 0)
			return services.map(({ affinity }) => affinity);

		return affinities;
	}

	next() {
		return super.next();
	}

	prev() {
		return super.prev();
	}

	skip() {
		return super.skip();
	}

	async init() {
		if (this.service?.midtail === false) return this.next();
		if (this.affinities.length === 1) {
			try {
				await this.updateService(this.affinities[0]._id);
			} catch (err) {
				throw err;
			}
		}
	}

	async updateService(serviceId) {
		const service = this.affinities.find(({ _id }) => _id === serviceId);
		try {
			await this.leadFunnelStore.Questions.init({ service });
		} catch (err) {
			throw err;
		}
		this.leadFunnelStore.Lead.midtail = this.service;
		this.leadFunnelStore.Lead.service = service;
		this.next();
	}
}

export default ChooseService;
