import Step from "../../../@extensions/Step";
import { runInAction } from "mobx";

class PhoneStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get lead() {
		return super.lead;
	}

	get progress() {
		return super.progress;
	}

	get phone() {
		return super.phone;
	}

	get textNotification() {
		return super.textNotification;
	}

	get selectedPros() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Lead.allSelectedPros;
	}

	getClient() {
		return super.getClient();
	}

	updateClient() {
		return super.updateClient();
	}

	updateClientNotification() {
		return super.updateClientNotification();
	}

	updatePhone(...args) {
		super.updatePhone(...args);
	}

	updateTextNotification(...args) {
		super.updateTextNotification(...args);
	}

	async init() {
		try {
			const { phone, notifications } = await this.getClient();
			runInAction(() => {
				this.updatePhone(phone);
				if (notifications) this.updateTextNotification(notifications.text);
			});
		} catch (err) {
			throw err;
		}
	}

	async next() {
		try {
			await Promise.all([this.updateClient()]);
			super.next();
		} catch (err) {
			throw err;
		}
	}

	skip() {
		window.location.href = `/client/projects/${this.lead.key}?status=new`;
	}

	prev() {
		super.prev();
	}
}

export default PhoneStore;
