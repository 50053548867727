import Step from "../../../@extensions/Step";
import required from "@libs/required";

class ZipcodeStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get textFieldStore() {
		return super.textFieldStore;
	}

	get progress() {
		return super.progress;
	}

	get zipcode() {
		return super.zipcode;
	}

	get service() {
		return super.service;
	}

	get focus() {
		return super.focus;
	}

	async init() {
		return super.init();
	}

	async next(ref = required`ref`) {
		try {
			if (this.textFieldStore.isValid(ref.current.id) === false) return;
			super.next();
		} catch (err) {
			throw err;
		}
	}

	prev() {
		super.prev();
	}

	update(val) {
		super.updateZipcode(val);
	}
}

export default ZipcodeStore;
