import required from "@libs/required";
import TravelDistanceStore from "../../Travel_Distance/@stores/TravelDistance.store";
import TravelDistanceCollapse from "./TravelDistanceCollapse";

class TravelDistanceCollapseStore extends TravelDistanceStore {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`) {
		super.init(question, answer, TravelDistanceCollapse);
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return super.findInstanceByAnswerId(answerId);
	}

	watchCollapse(answerId = required`answerId`) {
		return super.watchCollapse(answerId);
	}

	collapse(answerId = required`answerId`) {
		return super.collapse(answerId);
	}

	reset() {
		super.reset();
	}
}

export default TravelDistanceCollapseStore;
