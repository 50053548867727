import required from "@libs/required";
import Extension from "../../../@extensions/Answer";

import InputText from "./InputText";

class InputTextStore extends Extension {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`) {
		super.init(question, answer, InputText);
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return super.findInstanceByAnswerId(answerId);
	}

	reset() {
		super.reset();
	}
}

export default InputTextStore;
