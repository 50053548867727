import required from "@libs/required";
import DateOnlyStore from "../../DateOnly/@stores/DateOnly.store";
import DateOnlyCollapse from "./DateOnlyCollapse";

class DateOnlyCollapseStore extends DateOnlyStore {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`) {
		super.init(question, answer, DateOnlyCollapse);
	}

	collapse(answerId = required`answerId`) {
		return super.collapse(answerId);
	}

	watchCollapse(answerId = required`answerId`) {
		return super.watchCollapse(answerId);
	}

	reset() {
		super.reset();
	}
}

export default DateOnlyCollapseStore;
