import { observable, toJS } from "mobx";
import required from "@libs/required";

class AnswerInstanceExtensionHeader {
	@observable checked = false;
	question;
	answer;

	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		this.rootStore = rootStore;
		this.question = question;
		this.answer = answer;
	}

	@observable _collapse = false;

	get collapse() {
		return toJS(this._collapse);
	}

	get questions() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.value;
	}

	get nextElement() {
		return document.getElementsByClassName("question-next")[0];
	}

	get skipElement() {
		return document.getElementsByClassName("question-skip")[0];
	}

	get currentLeadAnswer() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Lead.findAnswerBySelectedAnswerId(this.answer._id);
	}

	get currentLeadAnswerValue() {
		if (!this.currentLeadAnswer) return;
		const index = this.currentLeadAnswer.answers.findIndex(
			(answerId) => answerId === this.answer._id
		);
		if (!~index) return;
		return this.currentLeadAnswer.values[index];
	}

	get addSelectedAnswer() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Lead.addSelectedAnswer;
	}

	get removeSelectedAnswer() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Lead.removeSelectedAnswer;
	}

	get getQuestionAnswerEnabler() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.getQuestionAnswerEnabler;
	}

	get isQuestionAnswerEnablerSelected() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.isQuestionAnswerEnablerSelected;
	}

	get isQuestionAnswerSelected() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.isQuestionAnswerSelected;
	}

	get getQuestionAnswerTarget() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.getQuestionAnswerTarget;
	}

	get isEnabler() {
		return !!this.getQuestionAnswerTarget(this.question._id, this.answer._id);
	}

	get isTarget() {
		return !!this.getQuestionAnswerEnabler(this.question._id, this.answer._id);
	}

	get listOfStores() {
		const names = [
			"dateStore",
			"dateCollapseStore",
			"dateOnlyStore",
			"dateOnlyCollapseStore",
			"inputCheckboxStore",
			"inputCheckboxTextStore",
			"inputRadioStore",
			"inputRadioTextStore",
			"inputTextStore",
			"textAreaStore",
			"travelDistanceStore",
			"travelDistanceCollapseStore",
		];
		return Object.keys(this.rootStore.stores)
			.filter((name) => names.includes(name))
			.filter((name) => !!this.rootStore.stores[name])
			.map((name) => this.rootStore.stores[name]);
	}

	get answerTarget() {
		if (this.isTarget) return;
		return this.getQuestionAnswerTarget(this.question._id, this.answer._id);
	}

	get answerEnabler() {
		if (this.isEnabler) return;
		return this.getQuestionAnswerEnabler(this.question._id, this.answer._id);
	}

	get targetStore() {
		if (!this.answerTarget) return;
		return this.listOfStores.find(
			(store) => !!store.findInstanceByAnswerId(this.answerTarget._id)
		);
	}

	get enablerStore() {
		if (!this.answerEnabler) return;
		return this.listOfStores.find(
			(store) => !!store.findInstanceByAnswerId(this.answerEnabler._id)
		);
	}

	get tag_value() {
		if (!this.currentLeadAnswer) return;
		if (!this.answer.tag_index) return;
		return this.currentLeadAnswer.tag_values[this.answer.tag_index];
	}

	get isChecked() {
		const isSelected = this.isQuestionAnswerSelected(
			this.question._id,
			this.answer._id
		);
		if (this.isEnabler && !isSelected) return this.checked;
		return isSelected;
	}
}

export default AnswerInstanceExtensionHeader;
