import required from "@libs/required";
import InstanceTextExtension from "../../../@extensions/AnswerInstanceText";

class InputText extends InstanceTextExtension {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get text() {
		return super.text;
	}

	init() {
		super.init();
	}

	update(val = required`val`) {
		super.updateText(val);
	}
}

export default InputText;
