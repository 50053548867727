/* global $ */

import Step from "../../../@extensions/Step";

class SummaryStore extends Step {
	delay = 700;

	constructor(rootStore) {
		super(rootStore);
	}

	get answers() {
		return super.lead.answers.splice(0, 5);
	}

	get maxTime() {
		return this.answers.length * this.delay;
	}

	get now() {
		return new Date();
	}

	async run() {
		try {
			const now = this.now;
			await super.createLead();

			const leadCreated = this.now;

			const timeElapse = leadCreated.getTime() - now.getTime();
			const remain = this.maxTime - timeElapse;
			this.next(remain < 0 ? 0 : remain);
		} catch (err) {
			throw err;
		}
	}

	next(delay = 0) {
		setTimeout(() => super.next(), delay);
	}
}

export default SummaryStore;
