import { observable, toJS } from "mobx";
import { questionsService } from "../@data/service";
import required from "@libs/required";
import { getParameterByName } from "@utils/Browser";

const { getQuestions } = questionsService;

class Questions {
	constructor(rootStore) {
		this.rootStore = rootStore;
		this.isQuestionAnswerEnablerSelected =
			this.isQuestionAnswerEnablerSelected.bind(this);
		this.getQuestionAnswerEnabler = this.getQuestionAnswerEnabler.bind(this);
		this.getQuestionAnswerTarget = this.getQuestionAnswerTarget.bind(this);
	}

	@observable _questions = [];
	get questions() {
		return toJS(this._questions);
	}

	get answers() {
		return this.questions
			.map((question) =>
				question.answers.map((answer) =>
					Object.assign(answer, { question: question._id })
				)
			)
			.flat();
	}

	get value() {
		return this.questions;
	}

	async init({ service }) {
		try {
			const questions = await getQuestions(
				{
					filterAnswers: { active: true },
					filter: { service: service._id, active: true, type: "lead" },
					sort: { position: 1 },
					sortAnswers: { position: 1 },
				},
				{ batch: true }
			);
			if (getParameterByName("preSelect") === "1") {
				for (let i = 0; i < questions.length; i++) {
					if (questions[i].answers.length > 1) {
						const hasPreSelected = questions[i].answers.find(
							(answer) => answer.selected
						);
						if (hasPreSelected) continue;
						questions[i].answers[0].selected = true;
					}
				}
			}
			this._questions = questions;
		} catch (err) {
			throw err;
		}
	}

	getQuestionAnswerEnabler(
		questionId = required`questionId`,
		targetAnswerId = required`targetAnswerId`,
		dataToggleType
	) {
		const question = this.questions.find(
			(question) => question._id === questionId
		);
		if (!question) return;

		return question.answers.find(
			(answer) =>
				answer["data_target"] === `${targetAnswerId}_${questionId}` &&
				(dataToggleType ? answer["data_toggle"] === dataToggleType : true)
		);
	}

	getQuestionAnswerTarget(
		questionId = required`questionId`,
		enablerAnswerId = required`enablerAnswerId`,
		dataToggleType
	) {
		const question = this.questions.find(
			(question) => question._id === questionId
		);
		if (!question) return;

		const answerEnabler = question.answers.find(
			(answer) => answer._id === enablerAnswerId
		);
		return question.answers.find(
			(answer) =>
				`${answer._id}_${questionId}` === answerEnabler["data_target"] &&
				(dataToggleType
					? answerEnabler["data_toggle"] === dataToggleType
					: true)
		);
	}

	isQuestionAnswerEnablerSelected(
		questionId = required`questionId`,
		targetAnswerId = required`targetAnswerId`,
		dataToggleType = required`dataToggleType`
	) {
		const answerEnabler = this.getQuestionAnswerEnabler(
			questionId,
			targetAnswerId,
			dataToggleType
		);
		if (!answerEnabler) return false;
		return this.isQuestionAnswerSelected(questionId, answerEnabler._id);
	}

	findQuestionByAnswerId(answerId = required`answerId`) {
		const answer = this.answers.find((answer) => answer._id === answerId);
		if (!answer) return;
		return this.questions.find((question) => question._id === answer.question);
	}

	findQuestionByAnswerKey(answerKey = required`answerKey`) {
		const answer = this.answers.find((answer) => answer.key === answerKey);
		if (!answer) return;
		return this.questions.find((question) => question._id === answer.question);
	}

	isQuestionAnswerSelected(
		questionId = required`questionId`,
		answerId = required`answerId`
	) {
		const { leadFunnelStore } = this.rootStore.stores;
		const { value } = leadFunnelStore.Lead.findAnswerByQuestionId(questionId);
		if (!value) return false;
		return !!value.answers.find((ansId) => ansId === answerId);
	}
}

export default Questions;
