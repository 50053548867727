import required from "@libs/required";
import DateOnly from "../../DateOnly/@stores/DateOnly";

class DateOnlyCollapse extends DateOnly {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get answerEnabler() {
		return super.answerEnabler;
	}

	get enablerStore() {
		return super.enablerStore;
	}

	get isTarget() {
		return super.isTarget;
	}

	get date() {
		return super.date;
	}

	get collapse() {
		return super.collapse;
	}

	updateObservableDate(type, val = required`val`) {
		super.updateObservableDate(type, val);
	}

	update(type = required`type`, val = required`val`) {
		if (!this.collapse) return;
		this.updateObservableDate(type, val);
		if (this.isTarget && this.answerEnabler && this.enablerStore) {
			const instance = this.enablerStore.findInstanceByAnswerId(
				this.answerEnabler._id
			);
			instance.add(this.answerEnabler.value);
		}
		super.update(type, val);
	}
}

export default DateOnlyCollapse;
