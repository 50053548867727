import { observable, action, toJS } from "mobx";
import required from "@libs/required";

import InstanceTextExtension from "../../../@extensions/AnswerInstanceText";

class InputCheckboxText extends InstanceTextExtension {
	@observable checked = false;

	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get text() {
		return super.text;
	}

	get isChecked() {
		return toJS(this.checked);
	}

	@action
	init() {
		super.init();
		if (!this.currentLeadAnswer) {
			this.checked = false;
			return;
		}
		this.checked = !!this.currentLeadAnswer.answers.find(
			(answerId) => answerId === this.answer._id
		);
	}

	@action
	focus() {
		if (!this.isChecked) {
			this.checked = true;
		}
	}

	@action
	toggle() {
		if (this.isChecked) {
			this.remove();
			this.checked = false;
		} else {
			super.add();
			this.focus();
		}
	}

	remove() {
		super.remove();
		this.updateText("");
	}

	@action
	updateText(val = required`val`) {
		super.updateText(val);
		this.checked = super.text.length > 0;
	}
}

export default InputCheckboxText;
