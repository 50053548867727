import { object, array, string } from "@src/libs/yup";

export const createLeadValidation = object({
	input: object({
		service: string().required(),
		provider: string().objectid().nullable(),
		answers: array().of(
			object({
				question: string()
					.objectid()
					.required("Please select at least one answer"),
				answers: array()
					.of(string().objectid())
					.required("Please select at least one answer"),
				values: array()
					.of(string())
					.required("Please select at least one answer"),
				description: string().required("Please select at least one answer"),
				tag_name: string().nullable(),
				tag_values: object().when("tag_name", {
					is: (tag_name) => !!tag_name,
					then: object().required(
						"answers.tag_values is required when a answers.tag_name is given"
					),
				}),
			}).default({})
		),
		zipcode: string().zipcode().required(),
		timezoneOffset: string().required(),
		leadActionType: string().required(),
	}),
});

export const signupAsClientValidation = object({
	input: object({
		password: string(),
		email: string()
			.email("Please enter a valid email")
			.required("Please enter an email"),
		first_name: string()
			.min(2, "First name must be greater than 1 character")
			.required("Please enter a valid name"),
		last_name: string()
			.min(1, "Last name must be at least 1 character")
			.required("Please enter a valid name"),
	}),
});

export const addLeadPicksValidation = object({
	leadKey: string().required(),
	providers: array().of(string().objectid()).min(1),
});
