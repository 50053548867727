import gql from "graphql-tag";
import { isBrowser } from "@utils/Browser";
import apolloClient from "@framework/apollo/client";
import DeviceFingerprint from "node_js_ipqs_device_tracker";

const { FINGERPRINT_TRACKER_KEY, SITE_NAME } = process.env;

let client;
if (isBrowser) {
	client = apolloClient({
		ssr: false,
		window,
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
			mutate: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

class Fingerprint {
	fields = [
		"email",
		"guid",
		"guid_confidence",
		"device_id",
		"unique",
		"is_crawler",
		"fraud_chance",
		"proxy",
		"vpn",
		"tor",
		"time",
		"country",
		"city",
		"recent_abuse",
		"active_vpn",
		"active_tor",
		"ip_address",
		"connection_type",
		"region",
		"timezone",
		"operating_system",
		"browser",
		"brand",
		"model",
		"mobile",
		"isp",
		"bot_status",
		"webgl_hash",
		"canvas_hash",
		"high_risk_device",
		"device_timezone",
		"ssl_fingerprint",
		"longitude",
		"latitude",
		"organization",
		"first_seen",
		"last_seen",
		"click_date",
		"request_id",
	];
	dateTimeFields = ["first_seen", "last_seen", "click_date"];

	get mutation() {
		return gql`
			mutation updateFingerprintV2($record: UpdateOneFingerprintInput!) {
				updateFingerprintV2(record: $record) {
					recordId
				}
			}
		`;
	}

	static parseDateTime(date) {
		// parse date in format 2024-09-29 19:52:53 to ISO format
		return new Date(date).toISOString();
	}

	parseFingerprint(fingerprint) {
		// select only keys that are part of the schema
		const parsedFingerprint = {};
		this.fields.forEach((field) => {
			if (fingerprint[field] !== undefined) {
				let value = fingerprint[field];
				if (this.dateTimeFields.includes(field)) {
					value = Fingerprint.parseDateTime(value);
				}
				parsedFingerprint[field] = value;
			}
		});
		return parsedFingerprint;
	}

	async initialize(email) {
		try {
			await DeviceFingerprint.initializeScriptAsync(FINGERPRINT_TRACKER_KEY);
		} catch (err) {
			console.error("DT Error");
			console.log(err);
			return;
		}
		DeviceFingerprint.Store("siteName", SITE_NAME);
		DeviceFingerprint.Store("email", email);
		await new Promise(async (resolve, reject) => {
			const callback = (result) => {
				if (result.success === true) {
					result.email = email;
					this.updateDB(result);
				}
				resolve();
			};
			DeviceFingerprint.AfterResult(callback);
			DeviceFingerprint.Init();
		});
	}

	async updateDB(fingerprint) {
		try {
			if (!fingerprint) {
				return;
			}
			const filteredFingerprint = this.parseFingerprint(fingerprint);
			await client.mutate({
				mutation: this.mutation,
				variables: { record: filteredFingerprint },
			});
		} catch (err) {
			throw err;
		}
	}
}
export default Fingerprint;
