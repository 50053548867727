import Step from "../../../@extensions/Step";
import { runInAction } from "mobx";
import { updateClientValidation } from "../../../@data/validation";

class PhoneZipStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get lead() {
		return super.lead;
	}

	get progress() {
		return super.progress;
	}

	get phone() {
		return super.phone;
	}

	get zipcode() {
		return super.zipcode;
	}

	get textNotification() {
		return super.textNotification;
	}

	get textFieldStore() {
		return super.textFieldStore;
	}

	get isClientAuthenticated() {
		return super.isClientAuthenticated;
	}

	getClient() {
		return super.getClient();
	}

	updateClient() {
		return super.updateClient();
	}

	updateClientNotification() {
		return super.updateClientNotification();
	}

	updatePhone(...args) {
		super.updatePhone(...args);
	}

	updateZipcode(...args) {
		super.updateZipcode(...args);
	}

	updateTextNotification(...args) {
		super.updateTextNotification(...args);
	}

	async init() {
		try {
			// if (!!super.leadFunnelStore.Client.phone) {
			// 	super.continue();
			// 	return;
			// }
			const { phone, notifications } = await this.getClient();
			runInAction(() => {
				this.updatePhone(phone);
				//this.updateTextNotification(notifications.text);
			});
		} catch (err) {
			throw err;
		}
	}

	async next(phoneRef, zipRef) {
		try {
			if (this.textFieldStore.isValid(phoneRef.current.id) === false) return;
			if (this.textFieldStore.isValid(zipRef.current.id) === false) return;
			await Promise.all([this.updateClient(), this.updateClientNotification()]);
			super.next();
		} catch (err) {
			throw err;
		}
	}

	validate() {
		if (!super.textFieldStore.isValidGroup("signup-form")) {
			throw new Error("Please enter a valid phone number");
		}
	}

	skip() {
		super.next();
	}

	prev() {
		this.updatePhone(null);
		if (this.isClientAuthenticated) super.prev("Question");
		else super.prev();
	}
}

export default PhoneZipStore;
