import required from "@libs/required";
import { observable } from "mobx";
import InstanceExtension from "../../../@extensions/AnswerInstance";

class InputRadio extends InstanceExtension {
	@observable checked = false;

	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
		const { leadFunnelQuestionStore, leadFunnelStore } = this.rootStore.stores;
		if (leadFunnelQuestionStore.completedQuestions.has(question._id)) {
			return;
		}
		if (leadFunnelStore.Lead.preSelectedQuestionIds.includes(question._id))
			return;
		if (answer.selected) this.add(answer.value);
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	get inputRadioStore() {
		const { inputRadioStore } = this.rootStore.stores;
		return inputRadioStore;
	}

	get inputRadioTextStore() {
		const { inputRadioTextStore } = this.rootStore.stores;
		return inputRadioTextStore;
	}

	get isChecked() {
		return super.isChecked;
	}

	add(val = required`val`) {
		this.inputRadioStore.remove();
		if (this.inputRadioTextStore) this.inputRadioTextStore.remove();
		super.add(val);
	}

	remove() {
		super.remove();
	}
}

export default InputRadio;
