import required from "@libs/required";
import { action, computed, observable, reaction, toJS } from "mobx";

import AnswerInstanceExtension from "./AnswerInstance";

class AnswerInstanceTextExtension extends AnswerInstanceExtension {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	@observable _text = "";
	@computed get text() {
		if (!this._text && super.currentLeadAnswer) {
			const index = super.currentLeadAnswer.answers.findIndex(
				(answerId) => answerId === this.answer._id
			);
			if (~index) {
				return toJS(super.currentLeadAnswer.values[index]);
			}
		}
		return toJS(this._text);
	}

	init() {
		super.init();
		reaction(
			() => this._text,
			(val) => {
				this.removeSelectedAnswer(this.answer._id);
				if (this.text.length > 0) super.addSelectedAnswer(this.answer._id, val);
			}
		);
		reaction(
			() => this._text.length,
			(length) => {
				if (length === 0) super.showSkip();
				else super.hideSkip();
			}
		);
	}

	@action
	updateText(val = required`val`) {
		this._text = val;
	}
}

export default AnswerInstanceTextExtension;
