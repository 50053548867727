import Lead from "../@libs/Lead";
import Client from "../@libs/Client";
import Control from "../@libs/Control";
import Questions from "../@libs/Questions";
import Provider from "../@libs/Provider";
import Service from "../@libs/Service";

import required from "@libs/required";
import { getParameterByName } from "@utils/Browser";
import { observable } from "mobx";

class LeadFunnelStore {
	Questions;
	Lead;
	Client;
	Control;

	currentNextHandler;
	leadActionType;

	dynamic = false;
	isLoaded = true;

	isModal = false;

	@observable initialized = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
		this.Lead = new Lead(rootStore);
		this.Client = new Client(rootStore);
		this.Control = new Control(rootStore);
		this.Questions = new Questions(rootStore);
		this.Provider = new Provider(rootStore);
		this.Service = new Service(rootStore);
	}

	get leadFunnelModalStore() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		return leadFunnelModalStore;
	}

	get serviceKey() {
		return getParameterByName("service");
	}

	get midtailKey() {
		return getParameterByName("midtail");
	}

	async init({
		provider,
		zipcode,
		service,
		midtail,
		isModal = false,
		Steps = required`Steps`,
		leadActionType,
		base64EncodedAnswerKeys,
	}) {
		try {
			this.isModal = isModal;
			this.leadActionType = leadActionType;
			this.Control.init(Steps);

			if (service?.key || service?._id) {
				service = await this.Service.get({
					filter: service.key ? { key: service.key } : { _id: service._id },
				});
			} else if (this.serviceKey || this.midtailKey) {
				service = await this.Service.get({
					filter: { key: this.serviceKey || this.midtailKey },
				});
				if (!service.midtail && this.midtailKey) {
					midtail = await this.Service.get({
						filter: { key: this.midtailKey },
					});
				}
			}

			await Promise.all([
				service && this.Questions.init({ service }),
				this.Client.init(),
				provider && this.Provider.init({ provider }),
			]);
			if (this.leadFunnelModalStore.zipcode)
				zipcode = this.leadFunnelModalStore.zipcode;
			this.Lead.init({
				provider,
				zipcode,
				service,
				midtail: service.midtail ? service : midtail,
				leadActionType,
				base64EncodedAnswerKeys,
			});
			if (this.dynamic) this.isLoaded = true;
			this.initialized = true;
		} catch (err) {
			throw err;
		}
	}

	reset() {
		this.Lead = new Lead(this.rootStore);
		this.Client = new Client(this.rootStore);
		this.Control = new Control(this.rootStore);
		this.Questions = new Questions(this.rootStore);
		this.Provider = new Provider(this.rootStore);
		this.Service = new Service(this.rootStore);
		this.initialized = false;
		const { proListItemStore } = this.rootStore.stores;
		if (proListItemStore?.selectedProvider)
			proListItemStore.selectedProvider = null;
	}
}

export default LeadFunnelStore;
