import required from "@libs/required";
import Extension from "../../../@extensions/Answer";

import TravelDistance from "./TravelDistance";

class TravelDistanceStore extends Extension {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`, Instance) {
		super.init(question, answer, Instance || TravelDistance);
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return super.findInstanceByAnswerId(answerId);
	}

	reset() {
		super.reset();
	}
}

export default TravelDistanceStore;
