import { observable, toJS } from "mobx";
import { providerService } from "../@data/service";

const { getProvider } = providerService;
class Provider {
	@observable provider;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	async init({ provider } = {}) {
		try {
			this.provider = provider;
			if (!this.provider?._id) return;

			this.provider = await getProvider(
				{ filter: { _id: this.provider._id } },
				{ batch: true }
			);
		} catch (err) {
			throw err;
		}
	}

	get value() {
		return toJS(this.provider);
	}
}
export default Provider;
