import Step from "../../../@extensions/Step";

import required from "@libs/required";

import Fingerprint from "@src/libs/Fingerprint";

class ContactStore extends Step {
	constructor(rootStore) {
		super(rootStore);
	}

	get textFieldStore() {
		return super.textFieldStore;
	}

	get email() {
		return super.email;
	}

	get progress() {
		return super.progress;
	}

	async init() {
		if (super.isClientAuthenticated) {
			const fingerprint = new Fingerprint();
			await fingerprint.initialize(this.email);
			return super.continue();
		}
	}

	update(val) {
		super.updateEmail(val);
	}

	next(ref = required`ref`) {
		if (this.textFieldStore.isValid(ref.current.id) === false) return;
		super.next();
	}

	prev() {
		super.prev();
	}
}

export default ContactStore;
