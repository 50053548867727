import { serviceService } from "../@data/service";

const { getService } = serviceService;

class Service {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get({ filter } = {}) {
		return getService({ filter }, { batch: true });
	}
}

export default Service;
