import { observable, action, toJS, computed } from "mobx";

import {
	clientService,
	loginService,
	signupService,
	accountService,
	clientNotificationService,
} from "../@data/service";
import required from "@libs/required";

const { clientByToken: getClient, updateClientByToken } = clientService;
const { updateClientNotificationByToken } = clientNotificationService;
const { login } = loginService;
const { signupAsClient } = signupService;
const { getAccountByToken } = accountService;
const { FB_ADS_ID } = process.env;
class Client {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get accountStore() {
		const { accountStore } = this.rootStore.stores;
		return accountStore;
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	@observable _email;
	@computed get email() {
		return toJS(this._email);
	}

	@observable _password;
	@computed get password() {
		return toJS(this._password);
	}

	@observable _name = "";
	@computed get name() {
		return toJS(this._name);
	}

	@observable _phone;
	@computed get phone() {
		return toJS(this._phone);
	}

	@observable _textNotification = false;
	@computed get textNotification() {
		return toJS(this._textNotification);
	}

	get first_name() {
		const words = this.name.split(" ");
		if (words.length <= 1) return words.shift();
		return words
			.filter((word, index) => index < Math.ceil(words.length / 2))
			.join(" ")
			.trim();
	}

	get last_name() {
		const words = this.name.split(" ");
		if (words.length <= 1) return "";
		return words
			.filter((word, index) => index >= Math.ceil(words.length / 2))
			.join(" ")
			.trim();
	}

	get value() {
		return {
			//track: {},
			first_name: this.first_name,
			last_name: this.last_name,
			password: this.password,
			email: this.email,
		};
	}

	get isAuthenticated() {
		const type = (this.accountStore.account || {}).type;
		return (
			this.accountStore.isAuthenticated &&
			(type === "CLIENT" || type === "USER")
		);
	}

	async init() {
		try {
			if (!this.isAuthenticated && this.accountStore.isAuthenticated) {
				const account = await getAccountByToken({}, { batch: true });
				this.updateObservableField("email", account.email);
			} else if (this.isAuthenticated && !this.email) {
				const account = await getAccountByToken({}, { batch: true });
				this.updateObservableField("email", account.email);
			}
			if (this.isAuthenticated && this.accountStore.isAuthenticated) {
				const { phone } = (await this.get()) || {};
				this._phone = phone;
			}
		} catch (err) {
			throw err;
		}
	}

	@action
	updateObservableField(field = required`field`, val) {
		this[`_${field}`] = val;
	}

	async signupAsClient() {
		try {
			if (!this.isAuthenticated && this.accountStore.isAuthenticated) {
				const account = await getAccountByToken();
				this.updateObservableField("email", account.email);
			}

			const { account } = await signupAsClient({ input: this.value });
			fbq("init", `${FB_ADS_ID}`, { external_id: account._id });
			gtag("set", { user_id: account._id });
			return account;
		} catch (err) {
			throw err;
		}
	}

	async login() {
		try {
			const { account } = await login({
				email: this.value.email,
				password: this.value.password,
			});
			this.accountStore.login(account, false);
		} catch (err) {
			throw err;
		}
	}

	async get() {
		return getClient();
	}

	async update() {
		try {
			if (!this.phone || this.phone.length === 0) {
				throw new Error("Phone is required");
			}
			await updateClientByToken(
				{ record: { phone: this.phone } },
				{ batch: true }
			);
		} catch (err) {
			throw err;
		}
	}

	async updateNotification() {
		try {
			await updateClientNotificationByToken(
				{
					input: {
						data: [{ field: "all_SMS", value: this.textNotification }],
					},
				},
				{ batch: true }
			);
		} catch (err) {
			throw err;
		}
	}
}

export default Client;
