import { observable, runInAction, action, computed, toJS } from "mobx";

import { searchService, leadService } from "../../../../@data/service";
import required from "@libs/required";

const { searchByLead, searchByAutoBill } = searchService;

const { PRE_SELECT_PROS, SITE_NAME } = process.env;
const { addLeadPicks } = leadService;

class LiaisonStore {
	unitTypes = {
		contactForPrice: "Contact for price",
	};

	@observable matches = [];

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@observable _picks = [];
	@computed get picks() {
		return toJS(this._picks);
	}

	get moreProsStore() {
		const { moreProsStore } = this.rootStore.stores;
		return moreProsStore;
	}

	get ogProvider() {
		return this.moreProsStore.leadFunnelStore.Provider.value;
	}

	get lead() {
		return this.moreProsStore.lead;
	}

	get isAllSelected() {
		return this.picks.length === this.matches.length;
	}

	@action
	async init() {
		try {
			const { response } = await searchByLead({
				leadKey: this.lead.key,
				answerKeyFilters: [""],
			});
			runInAction(() => {
				this.matches = response?.providers || [];
			});

			if (this.matches.length === 0) {
				this.moreProsStore.next();
			}

			let preSelectedProsCount = 0;
			for (const match of this.matches) {
				if (preSelectedProsCount >= PRE_SELECT_PROS) {
					break;
				}
				if (!this.isChecked(match.provider._id)) {
					this.addPick(match.provider._id);
					preSelectedProsCount++;
				}
			}
		} catch (err) {
			throw err;
		}
	}

	isChecked(proId = required`proId`) {
		return !!this.picks.find((id) => proId === id);
	}

	@action
	addPick(proId = required`proId`) {
		this._picks.push(proId);
	}

	@action
	selectAll() {
		this.matches.forEach((match) => {
			if (!this.isChecked(match.provider._id)) {
				this.addPick(match.provider._id);
			}
		});
	}

	@action
	deselectAll() {
		this._picks = [];
	}

	@action
	removePick(proId = required`proId`) {
		this._picks = this.picks.filter((pick) => pick !== proId);
	}

	displayUnitSpan(unit) {
		return !!unit;
	}

	subHeader(description) {
		return description;
	}

	displayChatBubble(total) {
		if (!total) return true;
		return false;
	}

	async complete() {
		try {
			if (this.picks.length > 0) {
				await addLeadPicks({
					leadKey: this.moreProsStore.lead.key,
					providers: this.picks,
					autoSelected: false,
				});
				this.moreProsStore.leadFunnelStore.Lead.picks =
					this.moreProsStore.leadFunnelStore.Lead.picks.concat(this.picks);
			}
		} catch (err) {
			throw err;
		}
	}
}

export default LiaisonStore;
