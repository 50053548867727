import required from "@libs/required";

import InstanceExtension from "../../../@extensions/AnswerInstance";

class InputCheckbox extends InstanceExtension {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
		const { leadFunnelQuestionStore, leadFunnelStore } = this.rootStore.stores;
		if (leadFunnelQuestionStore.completedQuestions.has(question._id)) {
			return;
		}
		if (leadFunnelStore.Lead.preSelectedQuestionIds.includes(question._id))
			return;
		if (answer.selected) this.add(answer.value);
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	get isChecked() {
		return super.isChecked;
	}

	add(val = required`val`) {
		super.add(val);
	}

	remove() {
		super.remove();
	}
}

export default InputCheckbox;
