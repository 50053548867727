import Step from "../../../@extensions/Step";
import required from "@libs/required";

import { accountService } from "../../../@data/service";

const { checkAccountStatus } = accountService;

class PasswordStore extends Step {
	isDualAccount;

	constructor(rootStore) {
		super(rootStore);
	}

	get textFieldStore() {
		return super.textFieldStore;
	}

	get accountStore() {
		return super.accountStore;
	}

	get progress() {
		return super.progress;
	}

	get password() {
		return super.password;
	}

	get isClientAuthenticated() {
		return super.isClientAuthenticated;
	}

	get email() {
		return super.email;
	}

	async init() {
		if (this.isClientAuthenticated) return super.continue();
		try {
			const { hasPassword, isClient, isProvider } = await checkAccountStatus({
				email: this.email,
			});
			if (!hasPassword && isClient) {
				await this.login();
				return super.continue();
			}
			if (!hasPassword && isProvider) {
				await Promise.reject("Account must have password");
				return;
			}
			if (!isClient && !isProvider) {
				return super.continue();
			}
			this.isDualAccount = isClient && isProvider;
		} catch (err) {
			throw err;
		}
	}

	async next(ref = required`ref`) {
		try {
			if (this.textFieldStore.isValid(ref.current.id) === false) return;
			if (this.isDualAccount) await this.login();
			super.next();
		} catch (err) {
			throw err;
		}
	}

	prev() {
		super.prev();
	}

	update(val) {
		super.updatePassword(val);
	}

	async login() {
		return super.login();
	}
}

export default PasswordStore;
