import { observable, action, computed, runInAction } from "mobx";

class AngiStore {
	constructor(rootStore, staticContext, libs) {
		this.rootStore = rootStore;
	}

	async complete() {
		try {
			return;
		} catch (err) {
			throw err;
		}
	}
}

export default AngiStore;
