import { observable, action } from "mobx";
import required from "@libs/required";

class Control {
	steps = [
		"Intro",
		"ChooseService",
		"Question",
		//"Zipcode",
		"Contact",
		"Password",
		"Name",
		"PhoneZip",
		"Summary",
		"MorePros",
		"Phone",
	];

	@observable currentStep = 0;
	prevStep = 0;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get accountStore() {
		const { accountStore } = this.rootStore.stores;
		return accountStore;
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	get leadFunnelModalStore() {
		const { leadFunnelModalStore } = this.rootStore.stores;
		return leadFunnelModalStore;
	}

	get isAuthenticated() {
		return this.accountStore.isAuthenticated;
	}

	get isClientAuthenticated() {
		return this.leadFunnelStore.Client.isAuthenticated;
	}

	get questions() {
		return this.leadFunnelStore.Questions.value;
	}

	get pages() {
		return this.steps.length + this.questions.length;
	}

	get page() {
		const { leadFunnelQuestionStore } = this.rootStore.stores;
		const questionStepIndex = this.steps.findIndex(
			(step) => this.Steps[step] === this.Steps["Question"]
		);
		if (this.currentStep < questionStepIndex) return this.currentStep;
		if (this.currentStep > questionStepIndex)
			return this.currentStep + this.questions.length;
		return this.currentStep + leadFunnelQuestionStore.currentStep;
	}

	get progress() {
		return parseInt(((this.page / this.pages) * 100).toFixed(0));
	}

	get Component() {
		return this.Steps[this.steps[this.currentStep]];
	}

	init(Steps = required`Steps`) {
		this.Steps = Steps;
	}

	@action
	next() {
		if (this.steps.length - 1 === this.currentStep) {
			this.leadFunnelModalStore.show = false;
			window.location.href = `/client/projects/${this.leadFunnelStore.Lead.value.key}`;
			return;
		}
		let inc = 1;

		this.prevStep = this.currentStep;
		this.currentStep = this.currentStep + inc;
	}

	@action
	prev(stepName) {
		if (stepName) {
			this.currentStep = this.steps.findIndex((step) => step === stepName);
			return;
		}
		if (this.currentStep === 0) return;
		let inc = 1;

		this.prevStep = this.currentStep;
		this.currentStep = this.currentStep - inc;
		if (this.currentStep < 0) this.currentStep = 0;
	}
}

export default Control;
