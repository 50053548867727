import { observable, action, toJS } from "mobx";
import required from "@libs/required";
import moment from "moment";

import InstanceExtension from "../../../@extensions/AnswerInstance";

class DateOnly extends InstanceExtension {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	@observable _date;
	get date() {
		return toJS(this._date);
	}

	get hiddenValue() {
		return `<div class=\\"hidden\\">${this.value}</div>`;
	}

	get value() {
		return toJS(this.date?.format("LL"));
	}

	get existingValue() {
		return this.currentLeadAnswerValue;
		const value = /<div [^>]+>(.*?)<\/div>/g.exec(this.currentLeadAnswerValue);
		if (!value) return;
		return value[1];
	}

	get addSelectedAnswer() {
		return super.addSelectedAnswer;
	}

	init() {
		super.init();
		if (this.tag_value) {
			this.update("date", moment(this.tag_value));
		} else if (this.existingValue) {
			this.update("date", moment(this.existingValue));
		}
	}

	@action
	updateObservableDate(type, val = required`val`) {
		if (type === "date") {
			this._date = val;
		}
	}

	update(type = required`type`, val = required`val`) {
		this.updateObservableDate(type, val);
		if (!this.value) return;
		this.addSelectedAnswer(this.answer._id, this.value, this.value);
	}
}

export default DateOnly;
