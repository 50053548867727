import { observable, action, toJS } from "mobx";
import required from "@libs/required";
import InstanceTextExtension from "../../../@extensions/AnswerInstanceText";

class InputRadioText extends InstanceTextExtension {
	@observable checked = false;

	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get inputRadioTextStore() {
		const { inputRadioTextStore } = this.rootStore.stores;
		return inputRadioTextStore;
	}

	get inputRadioStore() {
		const { inputRadioStore } = this.rootStore.stores;
		return inputRadioStore;
	}

	get text() {
		super.text;
	}

	get isChecked() {
		return toJS(this.checked);
	}

	@action
	init() {
		super.init();
		if (!this.currentLeadAnswer) {
			this.checked = false;
			return;
		}
		this.checked = !!this.currentLeadAnswer.answers.find(
			(answerId) => answerId === this.answer._id
		);
	}

	@action
	focus() {
		if (this.checked) return;
		this.inputRadioTextStore.remove();
		if (this.inputRadioStore) this.inputRadioStore.remove();
		if (!this.isChecked) {
			this.checked = true;
		}
	}

	add() {
		this.inputRadioTextStore.remove();
		if (this.inputRadioStore) this.inputRadioStore.remove();
		super.add();
		this.focus();
	}

	remove() {
		super.remove();
		this.updateText("");
	}

	@action
	updateText(val = required`val`) {
		super.updateText(val);
		this.checked = super.text.length > 0;
	}
}

export default InputRadioText;
