import required from "@libs/required";

import TravelDistance from "../../Travel_Distance/@stores/TravelDistance";
import { reaction } from "mobx";

class TravelDistanceCollapse extends TravelDistance {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get distance() {
		return super.distance;
	}

	get collapse() {
		return super.collapse;
	}

	init() {
		super.init();
		reaction(
			() => this.collapse,
			(collapse) =>
				collapse === true &&
				this.addSelectedAnswer(this.answer._id, this.hiddenValue, this.distance)
		);
	}

	update(val = required`val`) {
		if (!this.collapse) return;
		super.updateObservableDistance(val);
		if (this.isTarget && this.answerEnabler && this.enablerStore) {
			const instance = this.enablerStore.findInstanceByAnswerId(
				this.answerEnabler._id
			);
			instance.add(this.answerEnabler.value);
		}
		super.update(val);
	}
}

export default TravelDistanceCollapse;
