import gql from "graphql-tag";

export const questionsQueryFragment = gql`
	fragment questionsQueryFragment on Question {
		_id
		type
		description
		quickPick
		text
		skip
		key
		tag {
			name
		}
		header
	}

	fragment answersQueryFragment on Answer {
		_id
		type
		value
		selected
		data_toggle
		data_target
		tag_index
		subtext
		key
	}
`;

// export const searchForMatchesQuery = gql`
// 	query SearchForMatches($leadKey: ID!) {
// 		searchForMatches(leadKey: $leadKey) {
// 			_id
// 			metrics {
// 				reviews_avg
// 				reviews_count
// 				hires
// 			}
// 			image {
// 				value
// 			}
// 			company_name
// 		}
// 	}
// `;

export const searchByLeadQueryFragment = gql`
	fragment searchByLeadQueryFragment on Search {
		response {
			... on SearchByLeadResponse {
				providers {
					estimated_price(answerKeyFilters: $answerKeyFilters) {
						total
						unit
						description
					}
					... on SearchByLeadResponseProvider {
						provider {
							_id
							metrics {
								reviews_avg
								reviews_count
								hires
							}
							image {
								value
								hash
							}
							award {
								great_value
							}
							company_name
						}
					}
				}
			}
		}
	}
`;

export const searchByAutoBillQueryFragment = gql`
	fragment searchByAutoBillQueryFragment on Search {
		response {
			... on SearchByAutoBillResponse {
				providers {
					_id
					metrics {
						reviews_avg
						reviews_count
						hires
					}
					image {
						value
						hash
					}
					award {
						great_value
					}
					company_name
				}
			}
		}
	}
`;

export const clientQueryFragment = gql`
	fragment clientQueryFragment on Client {
		phone
		notifications {
			text
		}
	}
`;

export const providerQueryFragment = gql`
	fragment providerQueryFragment on Provider {
		_id
		image {
			hash
			value
		}
		company_name
		services {
			_id
			name
		}
	}
`;

// export const clientQuery = gql`
// 	query Client {
// 		client {
// 			phone
// 			notifications {
// 				text
// 			}
// 		}
// 	}
// `;

export const accountByTokenQueryFragment = gql`
	fragment accountByTokenQueryFragment on Account {
		email
	}
`;

export const clientByTokenQueryFragment = gql`
	fragment clientByTokenQueryFragment on Client {
		phone
	}
`;

export const serviceQueryFragment = gql`
	fragment serviceQueryFragment on Service {
		_id
		name
		midtail
		active
		plural
		affinities(filter: { active: true }) {
			affinity {
				_id
				name
				midtail
				name_proper
				active
			}
		}
	}
`;

export const findPartnerOpportunityQueryFragment = gql`
	fragment findPartnerOpportunityQueryFragment on PartnerOpportunity {
		partner
		partner_lead_key
		show_widget
		redirect_url
	}
`;
