import required from "@libs/required";
import DateStore from "../../Date/@stores/Date.store";
import DateCollapse from "./DateCollapse";

class DateCollapseStore extends DateStore {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`) {
		super.init(question, answer, DateCollapse);
	}

	collapse(answerId = required`answerId`) {
		return super.collapse(answerId);
	}

	watchCollapse(answerId = required`answerId`) {
		return super.watchCollapse(answerId);
	}

	reset() {
		super.reset();
	}
}

export default DateCollapseStore;
