import { computed, action, observable, toJS } from "mobx";

class LeadFunnelModalStore {
	zipcode; //set by the page triggering the modal
	data = {};
	@observable showModal = false;
	@observable fullWidth = true;
	@observable maxWidth = "sm";

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get show() {
		return this.showModal;
	}

	@computed set show(val) {
		this.showModal = val;
	}

	get leadFunnelStore() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore;
	}

	get leadFunnelQuestionStore() {
		const { leadFunnelQuestionStore } = this.rootStore.stores;
		return leadFunnelQuestionStore;
	}

	close() {
		this.show = false;
	}

	setData(key, value) {
		this.data[key] = value;
		this[key] = value;
	}

	clearData() {
		Object.keys(this.data).forEach((key) => (this[key] = undefined));
		this.data = {};
	}

	@action
	onClose(event, reason) {
		if (reason === "backdropClick") return;
		this.clearData();
		this.showModal = false;
		this.leadFunnelStore.reset();
		this.leadFunnelQuestionStore.reset();
	}

	@observable _leadActionType = null;
	@computed
	get leadActionType() {
		return toJS(this._leadActionType);
	}

	set leadActionType(leadActionType) {
		this._leadActionType = leadActionType;
	}
}

export default LeadFunnelModalStore;
