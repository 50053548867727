import DateOnly from "./DateOnly";
import required from "@libs/required";

import Extension from "../../../@extensions/Answer";

class DateOnlyStore extends Extension {
	constructor(rootStore) {
		super(rootStore);
	}

	init(question = required`question`, answer = required`answer`, Instance) {
		super.init(question, answer, Instance || DateOnly);
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return super.findInstanceByAnswerId(answerId);
	}

	watchCollapse(answerId = required`answerId`) {
		return super.watchCollapse(answerId);
	}

	collapse(answerId = required`answerId`) {
		return super.collapse(answerId);
	}

	reset() {
		super.reset();
	}
}

export default DateOnlyStore;
