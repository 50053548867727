import required from "@libs/required";
import { observable, action } from "mobx";

class AnswerExtension {
	@observable instances = [];

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	init(
		question = required`question`,
		answer = required`answer`,
		Instance = required`Instance`
	) {
		this.instances.push({
			answerId: answer._id,
			instance: new Instance(this.rootStore, { question, answer }),
		});
		this.instances[this.instances.length - 1].instance.init();
	}

	findInstanceByAnswerId(answerId = required`answerId`) {
		return (
			this.instances.find((instance) => instance.answerId === answerId) || {}
		).instance;
	}

	collapse(answerId = required`answerId`) {
		const instance = this.findInstanceByAnswerId(answerId);
		return instance.collapse;
	}

	watchCollapse(answerId = required`answerId`) {
		const instance = this.findInstanceByAnswerId(answerId);
		return instance.watchCollapse();
	}

	@action
	reset() {
		this.instances = [];
	}
}

export default AnswerExtension;
