import required from "@libs/required";
import InstanceExtension from "../../../@extensions/AnswerInstance";
import { action, observable, toJS } from "mobx";

const DEFAULT_DISTANCE = 15;

class TravelDistance extends InstanceExtension {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	get hiddenValue() {
		return `<div class=\\"hidden\\">${this.distance}</div>`;
	}

	get value() {
		return `${this.distance} miles`;
	}

	get existingValue() {
		const value = /<div [^>]+>(.*?)<\/div>/g.exec(this.currentLeadAnswerValue);
		if (!value) return;
		return value[1];
	}

	@observable _distance = DEFAULT_DISTANCE;
	get distance() {
		return toJS(this._distance);
	}

	@action
	init() {
		super.init();
		if (this.tag_value) {
			this.update(this.tag_value);
		} else if (this.existingValue) {
			this.update(this.existingValue);
		}
	}

	@action
	updateObservableDistance(val = required`val`) {
		this._distance = val;
	}

	update(val = required`val`) {
		this.updateObservableDistance(val);
		this.addSelectedAnswer(this.answer._id, this.hiddenValue, this.distance);
	}
}

export default TravelDistance;
