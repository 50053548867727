import { reaction, action, observable } from "mobx";
import required from "@libs/required";

import AnswerInstanceExtensionHeader from "./AnswerInstanceHeader";

class AnswerInstanceExtension extends AnswerInstanceExtensionHeader {
	constructor(
		rootStore,
		{ question = required`question`, answer = required`answer` }
	) {
		super(rootStore, { question, answer });
	}

	init() {
		if (super.currentLeadAnswer) {
			this.hideSkip();
		}
		if (
			super.isQuestionAnswerEnablerSelected(
				this.question._id,
				this.answer._id,
				"collapse"
			)
		) {
			this._collapse = true;
		}
	}

	hideSkip() {
		const { leadFunnelQuestionStore } = this.rootStore.stores;
		leadFunnelQuestionStore.showSkip = false;
	}

	showSkip() {
		const { leadFunnelQuestionStore } = this.rootStore.stores;
		leadFunnelQuestionStore.showSkip = true;
	}

	watchCollapse() {
		if (super.isTarget) {
			reaction(
				() => {
					if (super.answerEnabler && super.enablerStore) {
						const instance = super.enablerStore.findInstanceByAnswerId(
							super.answerEnabler._id
						);
						if (instance.isChecked !== undefined) {
							return instance.isChecked;
						}
					}
				},
				(enablerIsChecked) => (this._collapse = !!enablerIsChecked)
			);
		}
	}

	@action
	add(val) {
		if (super.isEnabler && super.answerTarget && super.targetStore) {
			const targetInstance = super.targetStore.findInstanceByAnswerId(
				super.answerTarget._id
			);
			const targetInstanceValue = targetInstance.value;
			if (targetInstanceValue)
				super.addSelectedAnswer(this.answer._id, targetInstanceValue, val);
			else this.checked = true;
			return;
		}
		super.addSelectedAnswer(this.answer._id, val);
	}

	@action
	remove() {
		if (super.isEnabler && super.answerTarget) {
			super.removeSelectedAnswer(super.answerTarget._id);
			this.checked = false;
		}
		super.removeSelectedAnswer(this.answer._id);
	}
}

export default AnswerInstanceExtension;
