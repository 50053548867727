import * as mutations from "./mutation";
import * as queries from "./query";
import * as validations from "./validation";

import QuestionService from "@services/Question";
import LeadService from "@services/Lead";
import ClientService from "@services/Client";
import ServiceService from "@services/Service";
import SearchService from "@services/Search";
import AccountService from "@services/Account";
import LoginService from "@services/Login";
import SignupService from "@services/Signup";
import ProviderService from "@services/Provider";
import ClientNotificationService from "@services/ClientNotification";

export const clientNotificationService = new ClientNotificationService({
	mutations,
	queries,
	validations,
});

export const providerService = new ProviderService({
	mutations,
	queries,
	validations,
});

export const leadService = new LeadService({ mutations, queries, validations });
export const clientService = new ClientService({
	mutations,
	queries,
	validations,
});
export const questionsService = new QuestionService({
	mutations,
	queries,
	validations,
});
export const serviceService = new ServiceService({
	mutations,
	queries,
	validations,
});
export const searchService = new SearchService({
	mutations,
	queries,
	validations,
});
export const accountService = new AccountService({
	mutations,
	queries,
	validations,
});
export const loginService = new LoginService({
	mutations,
	queries,
	validations,
});
export const signupService = new SignupService({
	mutations,
	queries,
	validations,
});
